import React from 'react'
import './Banner.css'
export default function Banner() {
  return (
    <div className="responsive-img">
    <div className='header'>
      <div className="">
<h1 >TechKnowGram</h1>
<h6>Solution | Performance | Comfort</h6>
</div>
    </div>
    </div>
  )
}
