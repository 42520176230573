import React from "react";
import Banner from "../../Component/Banner/Banner";
import Container from "../../Component/Card/Card";
import Container1 from "../../Component/Conainer1/Container1";
import Footer from "../../Component/Footer/Footer";
import Header from "../../Component/Header/Header";

export default function Home() {
  const construction = [
    {
      title: "Commercial",
      text: [
        "Tender Submission",
        "Yearly Tender Analysis",
        "Project wise Analysis",
        "Partner Share Management",
        "Partners List",
        "Clients List",
        "Tender Approval Matrices",
        "Budget, Demand, Expense Linkup and Details",
      ],
    },
  ];
  const Buget = [
    {
      title: "Budget Management",
      text: [
        "Budget Initiate",
        "Budget Approval",
        "Budget Update according to needs",
        "Budget Summary",
        "Budget Authorizing",
        "Company wise Budget",
        "Project wise Budget",
        "Budget Monitoring",
        "Item wise Budget",
        "Advanced Search",
      ],
    },
  ];
  const Project = [
    {
      title: "Project Management",
      text: [
        "Project Task Create",
        "Task Monitoring",
        "Task Assignment",
        "Project Monitoring",
        "Project Closing",
        "Project Approval Matrices",
        "Company wise project analysis",
      ],
    },
  ];
  const Attendance = [
    {
      title: "Attendance",
      text: [
        "Monthly Attendance ",
        "Individual Employee Attendance        ",
        "Attendance In Record        ",
        "Attendance Out Record",
        "Attendance-Late Management",
        "Individual Employee Spending time at office- Total hours, Less  hours",

        " Date wise Attendance Search",
        "Attendance Machine connected with ERP software",

        "Whenever an employee gives punch on attendance machine, punchdata will be stored in our ERP database. This is real time attendance system",
      ],
    },
  ];

  const Accounts = [
    {
      title: "Accounts & Finance",
      text: [
        "Journal Entry ",
        "Chart of Account Create",
        "Chart of Account List",
        "Trial Balance ",
        "Balance Sheet",
        "Income Statement",
        " General Ledger ",
        "Voucher Management",
        "Vouchers List",
        "General Journals ",
        "Ledger Management",
        "Bank Account Management",
        "Approval Matrices",
      ],
    },
  ];

  const Purchase = [
    {
      title: "Purchase Management",
      text: [
        "Demand Note create      ",
        "Demand VS Budget Comparison.       ",
        "Authorizing and Approval      ",
        "Weekly Demand Generate      ",
        "Weekly Demand Summary      ",
        "Date wise Demand      ",
        "Single Demand View      ",
      ],
    },
  ];

  const Store = [
    {
      title: "Store Management",
      text: [
        "Item Received Management",
        "Item Issued Management ",
        "Store wise items",
        "Store wise item details",
        "MRR Creation",
        "Linked with the account and finance",
        " Item Issue Approval Matrices",
      ],
    },
  ];
  const DMS = [
    {
      title: "DMS",
      text: [
        "DMS create      ",
        "DMS List      ",
        "Document Assignment: Who will renew and update the document",
        "Document Monitoring",
        "Document expire before one month ago will notify      ",
        "Document History      ",
        " Document finding Helper      ",
        "Date wise document search      ",
        "Approval Matrices      ",
      ],
    },
  ];

  const Ticketing = [
    {
      title: "Ticketing",
      text: [
        "Ticket Create for problem solving        ",
        "Problem Category based employee assigned        ",
        "Ticket Status Monitoring        ",
        "All activities with Ticketing is sent to emails        ",
        "Real time ticketing monitoring        ",
        "Notification",
        "Reports"
      ],
    },
  ];
  const Hrm = [
    {
      title: "HRM",
      text: [
        "Employee Data Monument        ",
        "Employee engaged with project management        ",
        "Employees with Different companies Management        ",
        "Leave Management        ",
        "Late Management",
        "Shift Management        ",
        " PF management        ",
        "Gratuity Management        ",
        "Calendar Management         ",
        "Allowance Management        ",
        "Deduction Management        ",
        "Advanced Search and Filter        ",
        
      ],
    },
  ];

  const Payrol = [
    {
      title: "Payrol",
      text: [
        "Salary Process        ",
        "Salary Approval Matrices        ",
        "Salary Hold Management        ",
        "Pay slip        ",
        "Monthly Salary        ",
        "Company wise salary        ",
        " Employee Type wise salary        ",
        "Group wise Salary        ",
        "Top Sheet PDF & Excel        ",
        "Bank Forwarding PDF & Excel        ",
        "Bank Forwarding Hold        ",
        "Advanced search & Filter        ",
      ],
    },
  ];
  return (
    <div>
      <Header />
      <Banner />
      <div className="container">
        <div className="row" style={{ marginTop: "20px", padding: "10px" }}>
          {construction.map(({ text, title }) => {
            return (
              <div className="col-md-4">
                <Container title={title} text={text} />
              </div>
            );
          })}
          {Buget.map(({ text, title }) => {
            return (
              <div className="col-md-4">
                <Container title={title} text={text} />
              </div>
            );
          })}
          {Project.map(({ text, title }) => {
            return (
              <div className="col-md-4">
                <Container title={title} text={text} />
              </div>
            );
          })}
        </div>
        <div className="row">
          {Attendance.map(({ text, title }) => {
            return (
              <div className="col-md-6">
                <Container1 title={title} text={text} />
              </div>
            );
          })}

          {Accounts.map(({ text, title }) => {
            return (
              <div className="col-md-6">
                <Container1 title={title} text={text} />
              </div>
            );
          })}
        </div>

        <div className="row">
          {Purchase.map(({ text, title }) => {
            return (
              <div className="col-md-4">
                <Container title={title} text={text} />
              </div>
            );
          })}

          {Store.map(({ text, title }) => {
            return (
              <div className="col-md-4">
                <Container title={title} text={text} />
              </div>
            );
          })}
          {DMS.map(({ text, title }) => {
            return (
              <div className="col-md-4">
                <Container title={title} text={text} />
              </div>
            );
          })}
        </div>
        <div className="row">
          {Ticketing.map(({ text, title }) => {
            return (
              <div className="col-md-4">
                <Container1 title={title} text={text} />
              </div>
            );
          })}

          {Hrm.map(({ text, title }) => {
            return (
              <div className="col-md-4">
                <Container1 title={title} text={text} />
              </div>
            );
          })}
          {Payrol.map(({ text, title }) => {
            return (
              <div className="col-md-4">
                <Container1 title={title} text={text} />
              </div>
            );
          })}
        </div>
      </div>
      <Footer/>
    </div>
  );
}
