import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";

function Container({title,text,}) {
  return (
    <CardGroup>
      <Card style={{height:"400px", border:"1px solid",borderRadius:"5px",marginTop:"20px",fontFamily:"inherit"}}>
        {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>
            {text.map(item => (
           <li>{item}</li> 

            ))}
           
          </Card.Text>
        </Card.Body>
      </Card>

    </CardGroup>
  );
}

export default Container;
